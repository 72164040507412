// bootstrap/mixin
@import "mixins";
@import "abstracts/variables";
@import "abstracts/mixins";
.tb_surface {
  padding: 4rem 0;
  width: 100%;
  &--light-gray {
    background-color: map-get($black-shades, 304);
  }
  &--gray {
    background-color: map-get($black-shades, 303);
  }
  &--dark-gray {
    background-color: map-get($black-shades, 301);
  }
  &--black {
    background-color: map-get($black-shades, 300);
  }

  &--gold-outline {
    outline: 1px solid map-get($colors, gold);
    outline-offset: -20px;
  }

  &--gold-outline-outside {
    outline: 1px solid map-get($colors, gold);
    padding: 2rem !important;
    outline-offset: -20px;
    @include medium-up {
      padding: unset;
      outline-offset: 20px;
    }
  }
}

.section-row {
  margin: 4rem 0;
}

.justify-center {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.container-fluid {
  // prevents horizontal scroll bar
  overflow-x: hidden;
}

$grid-breakpoints: (
  xs: 0,
  sm: 669px,
  md: 1019px,
  lg: 1439px,
  xl: 1809px,
);

$container-max-widths: (
  sm: 637px,
  md: 971px,
  lg: 1359px,
  xl: 1682px,
);

$grid-gutter-widths: (
  base: 16px,
  sm: 16px,
  md: 16px,
  lg: 32px,
  xl: 32px,
) !default;

// Custom margin breakpoints
$grid-margin-widths: (
  base: 16px,
  sm: 16px,
  md: 24px,
  lg: 40px,
  xl: 64px,
) !default;

$grid-gutter-width: 0;

/*
*   Customize gutter widths since bootstrap 4 doesn't have the ability
*/
@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: calc($grid-gutter-width / 2);

    // .row:not(.no-gutters) {
    //   margin-right: -$grid-gutter-half-width;
    //   margin-left: -$grid-gutter-half-width;
    // }

    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}

/**
*   Customize margins for container since bootstrap does not allow for this to be done by breakpoints
*/
@each $grid-breakpoint, $grid-margin-width in $grid-margin-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-margin-half-width: calc($grid-margin-width / 2);

    .container,
    .container-fluid,
    .container-#{$grid-breakpoint} {
      padding-right: $grid-margin-half-width;
      padding-left: $grid-margin-half-width;
    }
  }
}
