/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'abstracts/variables';
@import 'abstracts/mixins';
/* for sidenav to take whole page */

// @charset "UTF-8";

/*! -------------------------------
    * Cordial v0.1.3 | GPL 3.0 License | https://github.com/lylerohr/cordial

	* Cordial
	* Main Scss file
    *
	* Cordial is a BEM Sass and Atomic based framework that models the canonical
	* CSS Zen Garden minimalism and separation of scope.  The aim is to free
	* your markup form framework-specific classes and allow for full structural
	* and theme control via CSS.

	* Cordial utilizes the Brad Frost's, Atomic Design Methodology
	* (http://atomicdesign.bradfrost.com/) to organize absolutely anything.

------------------------------- */
// Font Imports
// @import "./assets/styles/partials/_config/theme/fonts";

// // Theme Imports
// @import "./assets/styles/partials/_config/theme/theme-config";
// @import "./assets/styles/partials/_config/theme/set-scale";

// @import "./assets/styles/base";
// @import "./assets/styles/partials/layout/layout";

// // COMBAK: Remove or comment for production
// @import "./assets/styles/partials/elements/elements";
// @import "./assets/styles/partials/components/components";
// @import "./assets/styles/partials/_component-library/component-library";

.text-center {
  text-align: center;
}

app-centered-text {
  width: 100%;
}

bsi-agegate {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noScroll {
  overflow: hidden;
}

.scrollImportant {
  overflow: auto !important;
}

// media carousel
app-media-carousel .ngucarousel-items {
  align-items: center;
}

// material overrides

mat-tab-group {
  min-width: 400px;
}

.mat-tab-label-content {
  font-size: 2.475rem;
  line-height: 1;
  display: block;
  white-space: normal;
  margin: 1.5rem 0;
  padding: 0;
  text-align: left;
  // font-family: gotham-light, Helvetica, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #171c1c;
  touch-action: auto;
  cursor: inherit;
  background-image: none;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  text-transform: uppercase;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: map-get($colors, gold) !important;
  border-color: map-get($colors, gold) !important;
  color: white !important;
}

.mat-step-header {
  .mat-step-icon {
    background-color: transparent;
    border: 1px solid map-get($colors, gold);
    color: map-get($colors, gold);
  }

  .mat-step-label {
    color: map-get($colors, gold);

    &.mat-step-label-active {
      color: map-get($colors, gold);
    }
  }
}

.birthday .mat-form-field {
  min-width: auto;
  width: 115px;

  @media screen and (max-width: 520px) {
    width: 180px;
  }
}

.mat-form-field {
  padding: 10px;
  min-width: 400px;

  @media screen and (max-width: 520px) {
    min-width: auto;
  }
}

.mat-checkbox-layout {
  margin: 0 5px;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: black !important;
  -webkit-text-fill-color: black !important;
}

.mat-tab-labels {
  .mat-tab-label {
    min-width: 10rem;
    height: auto;
    background-color: rgb(221, 221, 221);

    @media (max-width: 599px) {
      min-width: 160px;
    }
  }

  .mat-tab-label-active {
    background-color: white;
  }
}

.mat-ink-bar {
  display: none;
}

.mat-tab-header {
  border: 0;
}

mat-step-header {
  .mat-step-label {
    font-size: 1.25rem;
    line-height: 1.375;
    display: block;
    margin: 0 0 1rem 0;
    padding: 0;
    white-space: normal;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: left;
    touch-action: auto;
    cursor: inherit;
    background-image: none;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0);
    border: none gray;
  }
}

.mat-progress-bar {
  z-index: 9999;
  position: fixed !important;
}

.mat-progress-bar-fill::after {
  background-color: #4fc6db;
}

.mat-progress-bar-buffer {
  background-color: #000;
}

a:hover {
  text-decoration: none;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #4fc6db;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4fc6db;
}

.mat-dialog-container {
  background-color: black;
  overflow: hidden;
}

.registration-dialog,
.user-dialog,
.community-dialog {
  overflow: auto;

  .mat-dialog-container {
    background-color: white;
  }
}

.registration-dialog.clubSuntory {
  .mat-dialog-container {
    background-color: #000;
  }
}

// styles for snackbar
// snackbar styles
.tb-snackbar-info {
  color: white;
  background: #33b5e5;
}

.tb-snackbar-success {
  color: white;
  background: #00c851;
}

.tb-snackbar-error {
  color: white;
  background: #ff4444;
}

// override Tahoe hero card
@media (min-width: 1200px) {
  .tb_hero .tb_hero-card {
    width: 40% !important;
  }
}

@media (min-width: 1600px) {
  .tb_hero .tb_hero-card {
    width: 35% !important;
  }
}

.tb_hero-card {
  padding: 1.5rem 1.5rem;
}

.header-less-tabs .mat-tab-header {
  display: none;
}

.tb_expansion-panel__bottom.expanded {
  max-height: 3000px;
}

// Potential Join fix
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 !important;
  padding-bottom: 2px !important;
}

select.mat-input-element {
  padding: 1em 0;
  margin-bottom: 0 !important;
  top: 0 !important;
}

// auto fill removing blue background
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// .mat-form-field-label {
// 	color: #4FC6DB;
// }

.scrollImportant {
  body {
    height: auto;
  }
}

// main {
//   min-height: 100vh;
// }

.modal-dialog {
  .modal-content {
    background-color: transparent;
    border: none;
  }
}

.modal-content {
  align-items: center;
}

// due to the content being innerHTML we have to use a global styling for the p tag to be styled
.tb_two-up-section {
  p {
    color: white;
  }
}

// .tb-card__description {
//   p {
//     color: white;
//   }
// }

.tb_opener__copy {
  p {
    color: white;
  }
}

.tb-card__spotlight {
  .tb-card__description {
    span {
      color: white;
    }

    p {
      color: white;
    }
  }
}

.modal-dialog-centered:before {
  display: block;
  content: '';

  @media (min-width: 576px) {
    height: calc(100vh - 3.5rem);
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 404px;
    min-height: 250px;
  }
}

.home-featured-carousel {
  .ngucarousel .ngucarousel-items {
    margin-right: 50px;

    @include medium-up {
      margin-right: 400px;
    }
  }
}

.home-carousel {
  .ngucarousel .ngucarousel-items {
    margin-right: 50px;

    @include medium-up {
      margin-right: 300px;
    }
  }
}

.recommendations {
  .ngucarousel .ngucarousel-items {
    margin-right: 50px;

    @include medium-up {
      margin-right: unset;
    }
  }
}

body {
  overflow-x: hidden;
}

// TPB Registration Override

.terms-dialog {
  p.tb_copy {
    max-height: 320px;
    max-height: 320px;
  }

  p {
    color: white;
    padding: 1rem;
  }
}

// .glass {
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline,
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
  display: none;
}

form.your-cocktail {
  .mat-form-field input {
    line-height: 24px;
  }

  .mat-form-field.ng-invalid.ng-touched {
    border: none !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    display: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0;
  }

  // .mat-form-field-appearance-outline .mat-form-field-infix {
  //   padding-bottom: 0 !important;
  // }
  .mat-form-field-infix {
    border-top: unset;
  }

  .mat-input-element {
    box-sizing: border-box;
  }

  input {
    border: 1px solid #000000;
    padding: 12px;
  }
}

// }

// Homepage override
.home-container {
  .carousel {
    .h5 {
      font-size: 28px;
      padding: 16px 0 8px 0;
    }
  }
}

.eapps-instagram-feed-posts-grid-load-more-text {
  color: #ffffff;
  border: 2px solid transparent;
  border-radius: 100px;
  padding: 10px 35px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  background-color: #4fc6db;
  font-size: 0.875rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150%;
}

.eapps-instagram-feed-posts-grid-load-more {
  background-color: transparent !important;
}

// club suntory{
.clubSuntory {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
  }

  .mat-input-element {
    color: white;
  }
}

.mat-dialog-content.clubSuntory {
  max-height: unset;
}

