// Medium devices (Tablets, 670px and up)
@mixin medium-up {
  @media screen and (min-width: 670px) {
    @content;
  }
}
// Large devices (Desktops, 1020px and up)
@mixin large-up {
  @media screen and (min-width: 1020px) {
    @content;
  }
}
//  X Large devices (Large Screens, 1440px and up)
@mixin x-large-up {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

// 

@mixin header-mobile {
  @media screen and (max-width: 1210px) {
    @content;
  }
}
