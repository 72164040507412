@import 'abstracts/mixins';
@import 'abstracts/variables';

@font-face {
  font-family: 'Aegithalos';
  src: url('/assets/fonts/aegithalos.eot') format('embedded-opentype'),
    url('/assets/fonts/aegithalos.otf') format('opentype'),
    url('/assets/fonts/aegithalos.ttf') format('truetype'),
    url('/assets/fonts/aegithalos.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Majalla';
  src: url('/assets/fonts/majallab.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euphemia';
  src: url('/assets/fonts/euphemia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.impact {
  font-size: 3.378rem; //54px
  font-family: 'Aegithalos', serif;

  @include large-up {
    font-size: 5.875rem; //94px
  }
}

.h1 {
  font-size: 2rem;
  font-family: 'Aegithalos', serif;

  @include medium-up {
    font-size: 3rem; //48px
  }

  @include large-up {
    font-size: 4rem; //64px
  }
}

.h2 {
  font-size: 1.8rem;
  font-family: 'Aegithalos', serif;

  @include medium-up {
    font-size: 2.625rem; //42px;
  }

  @include large-up {
    font-size: 3.378rem; //54px
  }
}

.h3 {
  font-family: 'Aegithalos', serif;
  font-size: 1.75rem;

  @include medium-up {
    font-size: 2.25rem; //36px;
  }

  @include large-up {
    font-size: 3rem; //48px
  }
}

.h4 {
  font-family: 'Aegithalos', serif;
  font-size: 1.5rem;

  @include medium-up {
    font-size: 1.75rem; //28px
  }

  @include large-up {
    font-size: 2.625rem; //42px;
  }
}

.h5 {
  font-size: 1.25rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;

  @include medium-up {
    font-size: 1.5rem; //24px
  }

  @include large-up {
    font-size: 2.25rem; //36px;
  }
}

.h6 {
  font-size: 1.25rem; //20px
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.section-headline {
  font-size: 1rem; //16px
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.375;
  color: map-get($black-shades, 300);

  @include medium-up {
    font-size: 1.25rem;
  }
}

.paragraph-subheadline {
  font-size: 1rem;
}

.inputs {
  font-size: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  line-height: 24px;
  color: map-get($black-shades, 302);
}

.mini {
  font-size: 0.875rem; //14px
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: map-get($black-shades, 300);
}

.button-type {
  font-size: 0.875rem; //14px
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150%;
}

.label {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.75rem; //12px;
}

.text-link {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: map-get($colors, light-blue);
  font-size: 1rem;
}